@font-face {
font-family: '__epilogue_677c29';
src: url(/_next/static/media/e2043ca079e8e00a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__epilogue_677c29';
src: url(/_next/static/media/c4de337599ac381f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}@font-face {font-family: '__epilogue_Fallback_677c29';src: local("Arial");ascent-override: 95.66%;descent-override: 20.94%;line-gap-override: 0.00%;size-adjust: 111.49%
}.__className_677c29 {font-family: '__epilogue_677c29', '__epilogue_Fallback_677c29'
}.__variable_677c29 {--font-epilogue: '__epilogue_677c29', '__epilogue_Fallback_677c29'
}

@font-face {
ascent-override: 90%;
font-family: '__nichrome_d965bb';
src: url(/_next/static/media/0b07850b3cfe47ec-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
ascent-override: 90%;
font-family: '__nichrome_d965bb';
src: url(/_next/static/media/7dd108b00912a8a9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__nichrome_Fallback_d965bb';src: local("Arial");ascent-override: 110.71%;descent-override: 22.50%;line-gap-override: 0.00%;size-adjust: 86.36%
}.__className_d965bb {font-family: '__nichrome_d965bb', '__nichrome_Fallback_d965bb'
}.__variable_d965bb {--font-nichrome: '__nichrome_d965bb', '__nichrome_Fallback_d965bb'
}

